import React from "react";
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from "gatsby";

import "@assets/scss/App.scss";

import Layout from "@components/layout";
import Header from "@components/header/Header";
import BackToolbar from "@components/modules/back-toolbar/";
import PostJob from "@components/forms/post-job/";

import ImageTextModule from "@components/modules/image-text/";
import PopularSearch from "@components/popular-search/index";
import Footer from "@components/footer/";
import SEO from "@components/seo";

const PostJobPage = (props) => {
    const imgUrl = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "our-client-get-started-tile.png" }) {
                childImageSharp {
                    fluid(quality: 90, maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)
    return(
        <Layout>
            <SEO
                title="Employer | Estate Agent Job Vacancies London, UK"
                description="Property industry businesses use Crux Careers to fill PropTech jobs & estate agents vacancies.  We have great candidates for property jobs in London and the UK."
            />
            <Helmet 
                bodyAttributes={{
                    class: 'grey-bg'
                }}
            />
            <Header />
            <BackToolbar
                backLinkLabel="Back"
                showCreateAlert={false}
            />
            <PostJob
                title="Post your Real Estate Job Vacancies"
                intro="Enter details for property jobs in London and the UK here: we’ll match them with our top candidates."
                formClass=" form-dark mb-34 mb-md-51 mb-xl-0"
            />
            <ImageTextModule
                imgUrl={imgUrl.file.childImageSharp.fluid}
            />
            <PopularSearch {...props}
                sectionClass=" section-grey-bg"
            />
            <Footer />
        </Layout>
    )
}

export default PostJobPage
